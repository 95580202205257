import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { FaMinus, FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import Image from 'next/image';
import { useDispatch } from 'react-redux';
import {
  removeFromCart,
  updateQuantity
} from '../../../slices/cartSlice';

const truncateText = (text, wordLimit) => {
  const words = text.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
};

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    setMatches(media.matches);
    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [query]);

  return matches;
};

export const ProductListItem = ({ offer, vendorId }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery('(max-width: 800px)');

  const removeItemFromCart = (vendorId, productId, productVariantId) => {
    dispatch(removeFromCart({ vendorId, productId, productVariantId }));
  };

  const handleUpdateQuantity = (vendorId, productId, productVariantId, quantity) => {
    dispatch(updateQuantity({ vendorId, productId, productVariantId, quantity }));
  };

  return (
    <div key={offer.productId} className="p-2">
      <div
        className="flex cursor-pointer"
        onClick={() => router.push(`/product/${offer.productId}`)}
      >
        <div className="w-[3rem] min-w-[3rem] h-[3rem] relative overflow-hidden">
          <Image
            layout="fill"
            objectFit="contain"
            alt="Product image"
            src={offer.productDetail.Image}
          />
        </div>
        <span className="text-lg font-semibold ml-2 sm:ml-4">
          {isSmallScreen
            ? truncateText(offer.productDetail.name, 3)
            : offer.productDetail.name}
        </span>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-2 sm:mt-4">
        <span className="text-accent-orange-500">
          Rs. {offer.productDetail.price}
        </span>
        <div className="flex items-center mt-2 sm:mt-0">
          <span className="inline-flex justify-center items-center">
            <FaMinus
              className="mx-2"
              cursor="pointer"
              onClick={() => {
                if (offer.quantity > 1) {
                  handleUpdateQuantity(
                    vendorId,
                    offer.productId,
                    offer.productVariantId,
                    offer.quantity - 1
                  );
                }
              }}
            />
            {offer.quantity}
            <FaPlus
              className="mx-2"
              cursor="pointer"
              onClick={() => {
                if (offer.quantity < 10) {
                  handleUpdateQuantity(
                    vendorId,
                    offer.productId,
                    offer.productVariantId,
                    offer.quantity + 1
                  );
                }
              }}
            />
          </span>
          <span className="ml-4">
            <FaRegTrashAlt
              cursor="pointer"
              onClick={() =>
                removeItemFromCart(
                  vendorId,
                  offer.productId,
                  offer.productVariantId
                )
              }
            />
          </span>
        </div>
      </div>
    </div>
  );
};