import React, { useEffect, useState } from 'react'
import { ICategories } from '../../../lib/schema/categories'
import { getCategoriesApi } from '../../../utils/services'
import { NavBar } from '../NavBar'
import { useDispatch } from 'react-redux'
import { Footer } from '../../Footer'
import { addCategories } from '../../../slices/categorySlice'
import { useSession } from '../../../lib/hooks/auth'
import { useRouter } from 'next/router'
import { hasCookie } from 'cookies-next'

export const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  // const [categories, setCategories] = useState<ICategories | null>(null)
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchCategries = async () => {
      const response = await getCategoriesApi()
      const categories = response.data.data
      if (categories) {
        // setCategories(categories.data.data);
        dispatch(addCategories(categories))
      }
    }
    fetchCategries()
  }, [])
  const router = useRouter()

  const { session, logOut } = useSession()
  const isSession = session === undefined ? false : true
  const verifyCookies = hasCookie('qadamSession')
  useEffect(() => {
    if (!verifyCookies && router.pathname == '/checkout') {
      router.push('/login')
    }
    console.log({ 'router.pathname': router.pathname })
  }, [router.pathname])

  return (
    <main className="w-full min-h-screen">
      <NavBar />
      {/* <div className="w-full max-w-[1280px] mx-auto px-8 py-6">
        {crumbs && <BreadCrumbs crumbs={crumbs} />}
      </div> */}

      <div className="w-full max-w-[1280px] mx-auto px-8 pb-8">{children}</div>
      <Footer />
    </main>
  )
}
