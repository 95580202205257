export const Constants = {
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  statuses: {
    success: 200,
    unauthorized: 401,
    notfound: 404,
    serverError: 500,
  },
  endPoints: {
    login: '/api/auth/login',
    signup: '/api/auth/register',
    verifyOTP: '/api/auth/verify',
    getCategories: '/api/category',
    refreshToken: '/api/auth/token/refresh',
    resendOtp: '/api/auth/send-verify-email',
    getSingleProduct: '/api/products/',
    getProducts: '/api/products/',
    order: '/api/orders',
    getProductsByCategories: '/api/products/category/{categoryId}',
    getProductsBySubCategories:
      '/api/products/category/{categoryId}/subcategory/{subcategoryId}',
    getTrendingProducts: '/api/products/trending',
    getBestSellingProducts: '/api/products/best-selling',
    getAllOrders: '/api/orders/all',
    getSingleOrder: '/api/orders/',
    getCities: '/api/city',
    fileUpload: '/api/image/upload',
    forgotPass: '/api/auth/password/forgot',
    resetPassword: '/api/auth/password/reset',
    setProfile: '/api/profile/personal',
    getProfile: '/api/profile',
    getPayouts: '/api/profile/payouts',
    setBank: '/api/profile/bank',
    searchProduct:
      '/api/products/?search={searchTerm}&page={searchPage}&perPage={searchPerPage}',
    cancelOrder: '/api/orders/',
    acceptTerms: '/api/profile/terms',
  },
  errorMessages: {
    somethingWentWrong: 'Something went wrong',
  },
  general: {
    page: 1,
    subcategoryProductsPerPage: 8,
    trendingProductsPerPage: 8,
    bestSellingProductsPerPage: 8,
    viewAllProductsPerPage: 12,
    searchProductsPerPage: 8,
    ordersPerPage: 10,
  },
}
