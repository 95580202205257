import { ILoginData, ISignupData, IVerifyOTPData, IResendOTPData } from '../lib/schema/auth'
import { IOrderData } from '../lib/schema/orders'
import { Constants } from './Constants'
import axios from './axios'

export const refreshTokenApi = async (data) => {
  let response = await axios.post(Constants.endPoints.refreshToken, data)
  return response
}

export const getCategoriesApi = async () => {
  let response = await axios.get(Constants.endPoints.getCategories)
  return response
}

export const loginApi = async (data: ILoginData) => {
  let response = await axios.post(Constants.endPoints.login, data)
  return response
}

export const verifyOtp = async (data: IVerifyOTPData) => {
  const response = await axios.put(Constants.endPoints.verifyOTP, data)
  return response
}

export const signupApi = async (data: ISignupData) => {
  let response = await axios.post(Constants.endPoints.signup, data)
  return response
}

export const resendOtp = async (data: IResendOTPData) => {
  const response = await axios.post(Constants.endPoints.resendOtp, data)
  return response
}

export const getTrendingProductsApi = async (data) => {
  const response = await axios.get(
    `${Constants.endPoints.getTrendingProducts}?page=${data.page}&perPage=${data.itemPerPage}`
  )
  return response.data
}

export const getBestSellingProductsApi = async (data) => {
  const response = await axios.get(
    `${Constants.endPoints.getBestSellingProducts}?page=${data.page}&perPage=${data.itemPerPage}`
  )
  return response.data
}

export const getProductsApi = async (data) => {
  const response = await axios.get(
    `${Constants.endPoints.getSingleProduct}?page=${data.page}&perPage=${data.itemPerPage}`
  )
  return response
}

export const getSingleProductApi = async (data) => {
  const response = await axios.get(
    `${Constants.endPoints.getSingleProduct}${data}`
  )
  return response
}

export const createOrderApi = async (data: IOrderData) => {
  const response = await axios.post(Constants.endPoints.order, data)
  return response
}

export const getProductsBySubCategory = async (data) => {
  const response = await axios.get(
    `${Constants.endPoints.getProductsBySubCategories}?page={page}&perPage={itemPerPage}`
      .replace('{categoryId}', data.categoryId)
      .replace('{subcategoryId}', data.subcategoryId)
      .replace('{page}', data.page)
      .replace('{itemPerPage}', data.itemPerPage)
  )
  return response.data
}

export const getAllOrdersApi = async (data) => {
  const response = await axios.get(
    `${Constants.endPoints.getAllOrders}?page=${data.page}&perPage=${data.itemPerPage}&orderStatus=${data.orderStatus || ''}`
  )
  return response
}

export const getSingleOrderApi = async (data) => {
  const response = await axios.get(
    `${Constants.endPoints.getSingleOrder}${data}`
  )
  return response
}

export const getSearchProducts = async (data) => {
  const response = await axios.get(
    `${Constants.endPoints.getProducts}?page=${data.page}&perPage=${data.itemsPerPage}&search=${data.search}`
  )
  return response
}

export const getCitiesApi = async (courier) => {
  const response = await axios.get(
    `${Constants.endPoints.getCities}?provider=${courier}`
  )
  return response
}

export const uploadFile = async (data) => {
  const response = await axios.post(Constants.endPoints.fileUpload, data)
  return response
}

export const requestOtp = async (data) => {
  const response = await axios.put(Constants.endPoints.forgotPass, data)
  return response
}

export const resetPassword = async (data, token) => {
  const response = await axios.put(
    `${Constants.endPoints.resetPassword}?reset_token=${token}`,
    data
  )
  return response.data
}

export const setProfile = async (data) => {
  const response = await axios.put(Constants.endPoints.setProfile, data)
  return response
}

export const getProfile = async () => {
  const response = await axios.get(Constants.endPoints.getProfile)
  return response
}

export const getPayouts = async () => {
  const response = await axios.get(Constants.endPoints.getPayouts)
  return response
}

export const setBank = async (data) => {
  const response = await axios.put(Constants.endPoints.setBank, data)
  return response
}

export const cancelOrder = async (payload, id) => {
  const response = await axios.post(
    `${Constants.endPoints.cancelOrder}${id}/cancel`,
    payload
  )
  return response.data
}

export const acceptTerms = async (payload) => {
  const response = await axios.post(
    `${Constants.endPoints.acceptTerms}`,
    payload
  )
  return response.data
}
