import React, { useState, createContext, useReducer, useEffect } from 'react'
import { Action, sessionReducer } from './sessionReducer'
import { getCookie } from 'cookies-next'

export type Session = {
  accessToken: string
  refreshToken: string
  accessTokenExpireTime: number
  refreshTokenExpireTime: number
  userData?: {
    _id: string
    phone: string
    user_id: string
    bank_details?: {
      verification: {
        status: 'incomplete' | 'pending' | 'verified' | 'invalid'
        message: []
      }
    }
    profile_verification?: {
      status: 'incomplete' | 'pending' | 'verified' | 'invalid'
      message: []
    }
    createdAt?: string
    updatedAt?: string
  }
}

type Dispatch = (action: Action) => void

export type SessionState = {
  session: Session | undefined
}

const initialState = {
  session: undefined,
}

export const SessionContext = createContext<
  { state: SessionState; dispatch: Dispatch } | undefined
>(undefined)

export const SessionContextProvider = (props: {
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(sessionReducer, initialState)

  useEffect(() => {
    const storageSession = localStorage.getItem('qadamSession')
    if (storageSession) {
      dispatch({ type: 'LOGIN', payload: JSON.parse(storageSession) })
    }
  }, [])

  return (
    <SessionContext.Provider value={{ state, dispatch }}>
      {props.children}
    </SessionContext.Provider>
  )
}
