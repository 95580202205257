import axios, { AxiosInstance } from "axios";
import { Constants } from "./Constants";
import { getCookie, setCookie } from 'cookies-next';

// Create an axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: Constants.baseURL,
});

const refreshTokenApi = async (refreshToken: string) => {
  const axiosForRefresh = axios.create({
    baseURL: Constants.baseURL,
  });
  const data = { refreshToken };
  const response = await axiosForRefresh.post(Constants.endPoints.refreshToken, data);
  return response.data.data;
};

axiosInstance.interceptors.request.use(
  async (config) => {
    let cookies = getCookie('qadamSession');
    
    if (cookies) {
      let parsedCookies = JSON.parse(cookies);
      const currentTime = Date.now();

      // Check if access token is expired
      if (parsedCookies.accessTokenExpireTime < currentTime) {

        try {
          const refreshTokenResponse = await refreshTokenApi(parsedCookies.refreshToken);

          const updatedCookies = {
            accessToken: refreshTokenResponse.access_token,
            refreshToken: refreshTokenResponse.refresh_token,
            accessTokenExpireTime: Date.now() + (refreshTokenResponse.expires_in * 1000),
            refreshTokenExpireTime: Date.now() + (refreshTokenResponse.refresh_expires_in * 1000),
            userData: refreshTokenResponse.user_data || parsedCookies.userData
          };

          const newAccessToken = refreshTokenResponse.access_token;
          setCookie('qadamSession', JSON.stringify(updatedCookies), {
            maxAge: Math.abs(updatedCookies.refreshTokenExpireTime - Date.now()) / 1000,
            secure: true,
          });

          // Update the request headers with the new access token
          config.headers['Authorization'] = `Bearer ${newAccessToken}`;
        } catch (error) {
          console.error("Error refreshing token:", error);
          return Promise.reject(error);
        }
      } else {
        config.headers['Authorization'] = `Bearer ${parsedCookies.accessToken}`;
      }
    } else {
      // console.log("No cookies found, proceeding without authorization.");
    }
    return config;
  },
  (error) => {
    // console.log("Request error:", error);
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors globally
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log("Response error for:", error.config?.url, error);
    return Promise.reject((error.response && error.response.data) || "Something went wrong");
  }
);

export default axiosInstance;
