import { useContext } from 'react'
import { deleteCookie, setCookie } from 'cookies-next'
import { useRouter } from 'next/router'

import {
  Session,
  SessionContext,
} from '../../context/sessionContext/sessionContext'

export const useSession = () => {
  const sessionContext = useContext(SessionContext)
  const router = useRouter()

  if (sessionContext === undefined || null) {
    throw new Error('useSessionContext must be used within a SessionProvider')
  }

  const { state, dispatch } = sessionContext

  const logIn = (payload: Session) => {
    localStorage.setItem('qadamSession', JSON.stringify(payload))
    setCookie('qadamSession', JSON.stringify(payload), {
      maxAge: Math.abs(payload.refreshTokenExpireTime - Date.now()) / 1000,
      // maxAge: Date.now() + payload.refreshTokenExpireTime / 1000,
      // maxAge: 120,
      secure: true,
    })
    dispatch({
      type: 'LOGIN',
      payload,
    })
  }

  const logOut = () => {
    localStorage.clear()
    localStorage.removeItem('qadamSession')
    deleteCookie('qadamSession')
    dispatch({ type: 'LOGOUT' })
    router.push('/home')
  }

  return {
    session: state.session,
    logIn,
    logOut,
  }
}
