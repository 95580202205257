import { SessionState } from './sessionContext'

export type LoginAction = {
  type: 'LOGIN'
  payload: {
    accessToken: string
    refreshToken: string
    accessTokenExpireTime: number
    refreshTokenExpireTime: number
    userData?: {
      _id: string
      phone: string
      user_id: string
      bank_details?: {
        verification: {
          status: 'incomplete' | 'pending' | 'verified' | 'invalid'
          message: []
        }
      }
      profile_verification?: {
        status: 'incomplete' | 'pending' | 'verified' | 'invalid'
        message: []
      }
      createdAt?: string
      updatedAt?: string
    }
  }
}

export type LogoutAction = {
  type: 'LOGOUT'
}

export type Action = LoginAction | LogoutAction

export const sessionReducer = (state: SessionState, action: Action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        session: action.payload,
      }
    case 'LOGOUT':
      return {
        session: undefined,
      }
    default:
      throw new Error('Unhandled action type')
  }
}
