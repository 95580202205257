import '../styles/globals.css'
import { Footer } from '../components/Footer'
import { Provider } from 'react-redux'
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ChakraProvider } from '@chakra-ui/react'
import { chakraTheme } from '../styles/chakraTheme'
import store from '../store'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { AppWrapper } from '../components/layout/AppWrapper'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import "react-datepicker/dist/react-datepicker.css";
import { SessionContextProvider } from '../lib/context/sessionContext/sessionContext'
import Metas from '../utils/Metas';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
const queryClient = new QueryClient()

export default function MyApp({ Component, pageProps }: any) {
  let presister = persistStore(store)
  const router = useRouter();

  // useEffect(() => {
  //   // Define the paths where you want to disable scrolling
  //   const authPaths = ['/login', '/signup', '/forgot-password'];

  //   const handleOverflow = () => {
  //     // Check if the path is an auth page and if the width is greater than 767px
  //     if (authPaths.includes(router.pathname) && window.innerWidth > 767) {
  //       document.body.classList.add('overflow-hidden');
  //     } else {
  //       document.body.classList.remove('overflow-hidden');
  //     }
  //   };

  //   // Handle the overflow on mount
  //   handleOverflow();

  //   // Listen for window resize to update the overflow state
  //   window.addEventListener('resize', handleOverflow);

  //   // Cleanup the effect when the component unmounts or route changes
  //   return () => {
  //     document.body.classList.remove('overflow-hidden');
  //     window.removeEventListener('resize', handleOverflow);
  //   };
  // }, [router.pathname]);

  return (
    <>
      {/* <Metas title='qadam' /> */}
      <Provider store={store}>
        <link
          href="https://fonts.googleapis.com/css2?family=Work+Sans&display=swap"
          rel="stylesheet"
        />
        <ChakraProvider theme={chakraTheme}>
          <SessionContextProvider>
            <QueryClientProvider client={queryClient}>
              <PersistGate persistor={presister}>
                <AppWrapper>
                  <Component {...pageProps} />
                </AppWrapper>
              </PersistGate>
            </QueryClientProvider>
          </SessionContextProvider>
        </ChakraProvider>
      </Provider>
      <ToastContainer />
    </>
  )
}
