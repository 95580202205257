import { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import {
  IoMdNotificationsOutline,
  IoIosSearch,
  IoMdPerson,
  IoMdMenu,
  IoMdClose,
} from 'react-icons/io'
import { useSession } from '../../../lib/hooks/auth'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store'
import { getCookie, hasCookie } from 'cookies-next'
import Link from 'next/link'
import Image from 'next/image'
import { addToCart, emptyCart } from '../../../slices/cartSlice'
import { getUserDetail, getVerifyDetail } from '../../../utils/HelperService'
import { VerificationType } from '../../../lib/schema/auth'
import Logo from '../../../assets/logo.svg'
import mobLogo from '../../../assets/mob-logo.svg'
import styles from '../../navbar//navbar.module.scss'
import { CategoriesMenu } from './CategoriesMenu'
import { CartMenu } from '../../Cart/CartMenu'
import Marquee from 'react-fast-marquee'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const NavBar = () => {
  const dispatch = useDispatch()
  const { session, logOut } = useSession()
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const categories = useSelector(
    (state: RootState) => state.categories.categories
  )
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const verifyCookies = hasCookie('qadamSession')
  const isSession = session === undefined ? false : true
  const [profileVerification, setProfileVerification] = useState('')
  const [bankVerification, setBankVerification] = useState('')
  const router = useRouter()
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const [textSearch, setTextSearch] = useState('')
  const [isSearchPopupOpen, setSearchPopupOpen] = useState(false)
  const [isToggleMenuOpen, setIsToggleMenuOpen] = useState(false)
  const [parsedCookies, setParsedCookies] = useState(null)
  const getCookies = getCookie('qadamSession')
  const [marqueeMessage, setMarqueeMessage] = useState('')
  const [marqueeType, setMarqueeType] = useState('')

  useEffect(() => {
    const handleProfileUpdate = () => {
      let parsedCookies
      let response = getVerifyDetail()
      let userDetails = getUserDetail()

      if (session) {
        if (getCookies) {
          try {
            if (response) {
              setProfileVerification(response.profileVerification)
              setBankVerification(response.bankVerification)
            }
            parsedCookies = JSON.parse(getCookies)
            setParsedCookies(parsedCookies)

            if (
              response.profileVerification === 'incomplete' ||
              response.bankVerification === 'incomplete'
            ) {
              setMarqueeMessage(
                'Your profile or bank information is incomplete. Please update these details to proceed with your order.'
              )
              setMarqueeType('danger')
            } else if (
              response.profileVerification === 'pending' ||
              response.bankVerification === 'pending'
            ) {
              setMarqueeMessage(
                'Your profile or bank details are under review. Please wait for verification before placing an order.'
              )
              setMarqueeType('info')
            } else if (
              response.profileVerification === 'invalid' ||
              response.bankVerification === 'invalid'
            ) {
              let errorMessage = ''

              if (response.profileVerification === 'invalid') {
                errorMessage =
                  userDetails.userData.profile_verification?.message &&
                  userDetails.userData.profile_verification.message.length > 0
                    ? userDetails.userData.profile_verification.message[0]
                    : 'Your personal details are invalid. Please update your personal details.'
              }

              if (response.bankVerification === 'invalid') {
                const bankMessage =
                  userDetails.userData.bank_details.verification?.message &&
                  userDetails.userData.bank_details.verification.message
                    .length > 0
                    ? userDetails.userData.bank_details.verification.message[0]
                    : 'Your bank details are invalid. Please update your bank details.'

                errorMessage = errorMessage
                  ? `${errorMessage} ${bankMessage}`
                  : bankMessage
              }

              setMarqueeMessage(errorMessage)
              setMarqueeType('danger')
            } else if (
              userDetails.userData.arrears < 0 ||
              userDetails.userData.currentEarning < 0
            ) {
              setMarqueeMessage('Please clear your dues to place an order.')
              setMarqueeType('danger')
            } else {
              setMarqueeMessage('')
              setMarqueeType('')
            }
          } catch (error) {
            console.error('Error parsing JSON:', error)
          }
        }
      }
    }

    handleProfileUpdate()

    window.addEventListener('profileUpdated', handleProfileUpdate)

    return () => {
      window.removeEventListener('profileUpdated', handleProfileUpdate)
    }
  }, [session])

  useEffect(() => {
    // if (!router.asPath.includes('/search') && textSearch) {
    setTextSearch('')
    // }
  }, [router])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  const handleResize = () => {
    const width = window.innerWidth
    setIsMobile(width < 800)
    setIsTablet(width >= 800 && width < 940)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleToggleMenu = () => {
    setIsToggleMenuOpen((prevState) => !prevState)
  }

  const handleMenuItemClick = () => {
    setIsToggleMenuOpen(false)
  }

  const handleSearch = () => {
    if (textSearch && textSearch.trim().length > 0) {
      const sanitizedSearch = textSearch
        .trim()
        .replace(/\s+/g, ' ')
        .replace(/[^\w\s-]/g, '')
      router.push(`/search/${sanitizedSearch}`)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchPopupOpen(false)
      handleSearch()
    }
  }

  return (
    <>
      <div>
        {verifyCookies && marqueeMessage && marqueeType === 'danger' && (
          <Marquee className="bg-red-500 text-white p-2">
            {marqueeMessage}
          </Marquee>
        )}
        {verifyCookies && marqueeMessage && marqueeType === 'info' && (
          <Marquee className="bg-yellow-500 text-black p-2">
            {marqueeMessage}
          </Marquee>
        )}
      </div>
      <header
        id="nav-header"
        className="h-[5rem] w-full bg-white sticky top-0 shadow-md z-50 flex items-center justify-center"
      >
        <div
          className={`grid ${isMobile ? 'grid-cols-2' : 'lg:grid-cols-3 grid-cols-3'} max-w-[1280px] mx-auto w-full px-8`}
        >
          <div className="flex items-center w-full h-full">
            <Link href="/home">
              <Image
                onClick={() => {
                  router.push('/home')
                }}
                className={styles['organization-logo']}
                src={isTablet || isMobile ? mobLogo : Logo}
                alt="logo"
              />
            </Link>
            {!isMobile && (
              <div className="ml-4">
                <CategoriesMenu categories={categories} />
              </div>
            )}
          </div>

          {!isMobile ? (
            <>
              <div className="flex items-center w-full h-full ml-3">
                <div className="w-[30rem] flex items-center relative">
                  <input
                    className="border-2 border-gray-300 rounded-lg p-1 w-full pl-4"
                    placeholder="Search..."
                    onChange={(event) => setTextSearch(event.target.value)}
                    value={textSearch}
                    onKeyDown={handleKeyDown}
                  />
                  <div onClick={handleSearch} className="absolute right-3 ml-2">
                    <IoIosSearch fontSize="1.5rem" color="gray" />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end w-full h-full gap-4">
                {verifyCookies && isSession && !isMobile && (
                  <div className="ml-3">
                    <Link href="/profile/orders">
                      <p className="text-primary-blue-500 font-semibold text-l">
                        Orders
                      </p>
                    </Link>
                  </div>
                )}
                <div className="relative" ref={dropdownRef}>
                  <button
                    type="button"
                    onClick={toggleDropdown}
                    className="p-2 mr-1 text-primary-blue-500 rounded-lg"
                  >
                    <span className="sr-only">View notifications</span>
                    {isSession && <IoMdNotificationsOutline size={24} />}
                  </button>
                  {isOpen && isSession  &&(
                    <div className="absolute right-0 mt-2 w-72 bg-white rounded-md shadow-lg z-50 overflow-hidden">
                      <div>
                        <a
                          href="#"
                          className="flex py-3 px-4 border-b hover:bg-gray-100"
                        >
                          <div className="pl-3 w-full">
                            <div className="text-primary-blue-500 font-normal text-sm mb-1.5">
                              Complete your profile
                            </div>
                            <div className="text-xs font-medium text-primary-700">
                              a few moments ago
                            </div>
                          </div>
                        </a>
                        <a
                          href="#"
                          className="block py-2 text-base font-medium text-center text-gray-900 bg-gray-50 hover:bg-gray-100"
                        >
                          <div className="inline-flex items-center">
                            View all
                          </div>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                <CartMenu />
                {!(verifyCookies && isSession) ? (
                  <button
                    onClick={() => router.push('/login')}
                    className="text-accent-orange-500 font-[600] text-lg hover:bg-accent-orange-500 hover:text-[#ffffff] rounded-lg py-1 px-2 transition-all"
                  >
                    {isTablet ? 'Login' : 'Login / Register'}
                  </button>
                ) : (
                  <div className="relative">
                    <div
                      className="flex items-center cursor-pointer text-accent-orange-500"
                      onMouseEnter={() => setIsMenuOpen(true)}
                      onMouseLeave={() => setIsMenuOpen(false)}
                      onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                      <IoMdPerson fontSize="2rem" />
                    </div>
                    {isMenuOpen && (
                      <div
                        onMouseEnter={() => setIsMenuOpen(true)}
                        onMouseLeave={() => setIsMenuOpen(false)}
                        className="absolute top-full right-0 w-48 bg-white shadow-lg rounded-lg ring-1 ring-black ring-opacity-5"
                      >
                        <div className="py-1">
                          <span
                            className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => {
                              router.push('/profile')
                              setIsMenuOpen(false)
                            }}
                          >
                            Profile
                          </span>
                          <span
                            className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => {
                              router.push('/profile/orders')
                              setIsMenuOpen(false)
                            }}
                          >
                            Orders
                          </span>
                          {/* <span
                            className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => {
                              router.push('/orders')
                              setIsMenuOpen(false)
                            }}
                          >
                            Finance
                          </span> */}
                          <span
                            className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => {
                              AsyncStorage.setItem('bank_details', '1')
                              router.push('/profile')
                              setIsMenuOpen(false)
                            }}
                          >
                            Bank Details
                          </span>
                          {/* <span
                            className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => {
                              router.push('/profile')
                              setIsMenuOpen(false)
                            }}
                          >
                            Favourites
                          </span> */}
                          <span
                            className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => {
                              router.push('/terms-and-conditions')
                              setIsMenuOpen(false)
                            }}
                          >
                            Terms and Conditions
                          </span>
                          <span
                            className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => {
                              router.push('/faq')
                              setIsMenuOpen(false)
                            }}
                          >
                            FAQ
                          </span>
                          <span
                            className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => {
                              router.push('/help-and-support')
                              setIsMenuOpen(false)
                            }}
                          >
                            Help & Support
                          </span>
                          <span
                            className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => {
                              logOut()
                              setIsMenuOpen(false)
                              dispatch(emptyCart())
                            }}
                          >
                            Logout
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="flex items-center justify-end w-full h-full gap-0">
              <button
                className="p-2 text-primary-blue-500"
                onClick={() => setSearchPopupOpen(true)}
              >
                <IoIosSearch size={20} />
              </button>
              {isSearchPopupOpen && (
                // <div className="absolute inset-0 bg-white z-50 flex items-center justify-center backdrop-blur-sm p-4 md:p-8">
                //   <div className="relative w-full max-w-md">
                //     <input
                //       className="border-2 border-gray-300 rounded-lg p-2 w-full "
                //       placeholder="Search..."
                //       onChange={(event) => setTextSearch(event.target.value)}
                //       value={textSearch}
                //       onKeyDown={handleKeyDown}
                //     />
                //     <button
                //       className="absolute top-2 right-2 text-primary-blue-500"
                //       onClick={() => setSearchPopupOpen(false)}
                //     >
                //       <IoMdClose size={24} />
                //     </button>
                //   </div>
                // </div>
                <div className="absolute inset-0 bg-white z-50 flex items-center justify-center backdrop-blur-sm p-4 md:p-8">
                  <div className="relative w-full max-w-md">
                    <input
                      className="border-2 border-gray-300 rounded-lg p-2 pl-3 pr-10 w-full" // Adjust padding to create space for the button
                      placeholder="Search..."
                      onChange={(event) => setTextSearch(event.target.value)}
                      value={textSearch}
                      minLength={1}
                      maxLength={120}
                      onKeyDown={handleKeyDown}
                    />
                    <button
                      className="absolute top-2 right-2 text-primary-blue-500"
                      onClick={() => setSearchPopupOpen(false)}
                      style={{ zIndex: 10 }} // Ensure it stays above other elements
                    >
                      <IoMdClose size={24} className="mt-[3px]" />
                    </button>
                  </div>
                </div>
              )}
              <CartMenu />
              <div className="relative" ref={dropdownRef}>
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="p-2 mr-1 text-primary-blue-500 rounded-lg"
                >
                  <span className="sr-only">View notifications</span>
                  {isSession && <IoMdNotificationsOutline size={20} />}
                </button>
                {isOpen && isSession && (
                  <div className="absolute right-0 mt-2 w-72 bg-white rounded-md shadow-lg z-50 overflow-hidden">
                    <div>
                      <a
                        href="#"
                        className="flex py-3 px-4 border-b hover:bg-gray-100"
                      >
                        <div className="pl-3 w-full">
                          <div className="text-primary-blue-500 font-normal text-sm mb-1.5">
                            Complete your profile
                          </div>
                          <div className="text-xs font-medium text-primary-700">
                            a few moments ago
                          </div>
                        </div>
                      </a>
                      <a
                        href="#"
                        className="block py-2 text-base font-medium text-center text-gray-900 bg-gray-50 hover:bg-gray-100"
                      >
                        <div className="inline-flex items-center">View all</div>
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <button
                onClick={handleToggleMenu}
                className="p-2 text-accent-orange-500 rounded-lg relative flex items-center justify-center"
              >
                {isToggleMenuOpen ? (
                  <IoMdClose
                    size={20}
                    className="transition-transform duration-300"
                  />
                ) : (
                  <IoMdMenu
                    size={20}
                    className="transition-transform duration-300"
                  />
                )}
              </button>
              {isToggleMenuOpen &&
                (isSession ? (
                  <div className="absolute top-full right-0 w-full bg-white shadow-lg rounded-lg ring-1 ring-black ring-opacity-5 z-50">
                    <div className="py-1">
                      <Link href="/profile">
                        <span
                          className="cursor-pointer block px-8 py-2 text-sm text-gray-700"
                          onClick={handleMenuItemClick}
                        >
                          Profile
                        </span>
                      </Link>
                      <Link href="/profile/orders">
                        <span
                          className="cursor-pointer block px-8 py-2 text-sm text-gray-700"
                          onClick={handleMenuItemClick}
                        >
                          Orders
                        </span>
                      </Link>
                      <Link href="/profile">
                        <span
                          className="cursor-pointer block px-8 py-2 text-sm text-gray-700"
                          onClick={() => {
                            handleMenuItemClick()
                            AsyncStorage.setItem('bank_details', '1')
                            router.push('/profile')
                            setIsMenuOpen(false)
                          }}
                        >
                          Bank Details
                        </span>
                      </Link>
                      <Link href="/terms-and-conditions">
                        <span
                          className="cursor-pointer block px-8 py-2 text-sm text-gray-700"
                          onClick={handleMenuItemClick}
                        >
                          Terms and Conditions
                        </span>
                      </Link>
                      <Link href="/faq">
                        <span
                          className="cursor-pointer block px-8 py-2 text-sm text-gray-700"
                          onClick={handleMenuItemClick}
                        >
                          FAQ
                        </span>
                      </Link>

                      <Link href="/help-and-support">
                        <span
                          className="cursor-pointer block px-8 py-2 text-sm text-gray-700"
                          onClick={handleMenuItemClick}
                        >
                          Help & Support
                        </span>
                      </Link>

                      {!isSession ? (
                        <span
                          className="cursor-pointer block px-8 py-2 text-sm text-gray-700"
                          onClick={() => {
                            handleMenuItemClick()
                            router.push('/login')
                          }}
                        >
                          Login / Register
                        </span>
                      ) : (
                        <span
                          className="cursor-pointer block px-8 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => {
                            logOut()
                            handleMenuItemClick()
                            dispatch(emptyCart())
                          }}
                        >
                          Logout
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="absolute top-full right-0 w-full bg-white shadow-lg rounded-lg ring-1 ring-black ring-opacity-5 z-50">
                    <div className="py-1">
                      <Link href="/faq">
                        <span
                          className="cursor-pointer block px-8 py-2 text-sm text-gray-700"
                          onClick={handleMenuItemClick}
                        >
                          FAQ
                        </span>
                      </Link>

                      {!isSession ? (
                        <span
                          className="cursor-pointer block px-8 py-2 text-sm text-gray-700"
                          onClick={() => {
                            handleMenuItemClick()
                            router.push('/login')
                          }}
                        >
                          Login / Register
                        </span>
                      ) : (
                        <span
                          className="cursor-pointer block px-8 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => {
                            logOut()
                            handleMenuItemClick()
                            dispatch(emptyCart())
                          }}
                        >
                          Logout
                        </span>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </header>
    </>
  )
}

/*
"      test product"
"testproduct         "
"test      product"
"test product 7 other search term ( with-special_characters?)"
*/
