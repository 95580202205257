import { getCookie, setCookie } from 'cookies-next'
import { UserDetailsParam } from '../lib/schema/auth'

export const getVerifyDetail = () => {
  try {
    let res = getCookie('qadamSession')

    if (!res) {
      return null
    }

    let response: UserDetailsParam
    try {
      response = JSON.parse(res)
    } catch (error) {
      return null
    }

    if (
      response?.userData &&
      response.userData.bank_details &&
      response.userData.profile_verification
    ) {
      return {
        bankVerification: response.userData.bank_details.verification.status,
        profileVerification: response.userData.profile_verification.status,
      }
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}
export const getUserDetail = () => {
  try {
    let res = getCookie('qadamSession')

    if (!res) {
      return null
    }

    let response: UserDetailsParam
    try {
      response = JSON.parse(res)
      return response
    } catch (error) {
      return null
    }
  } catch (error) {
    return null
  }
}
export const setUserDetail = (payload: UserDetailsParam) => {
  try {
    localStorage.setItem('qadamSession', JSON.stringify(payload))
    setCookie('qadamSession', JSON.stringify(payload), {
      maxAge: Math.abs(payload.refreshTokenExpireTime - Date.now()) / 1000,
      secure: true,
    })
  } catch (error) {
    return null
  }
}

export const toTitleCase = (str) => {
  return str?.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
}
